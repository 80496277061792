import React, { FC } from 'react'
import styled from 'styled-components'

const StyContainer = styled.div<{backgroundColor: string}>`
  position: relative;
  
  width: 450px;
  height: min-content;
  
  margin: 1rem;
  padding: 2rem;
  
  box-shadow: 0.3rem 0.3rem 1rem -0.1rem rgba(0, 0, 0, 0.7);
  background-color: ${props => props.backgroundColor};

  @media only screen and (max-width: 800px) {
    padding: 1rem;
    margin: 0rem 0rem 2rem 0rem;
    width: min(450px, 100%);
  }
`

const StyLink = styled.a`
  color: unset;
  position: relative;
`

const StyImage = styled.img`
  width: 100%;
  box-shadow: 0 0.2rem 0.3rem 0 rgba(0, 0, 0, 0.8);
`

const StyTitle = styled.div<{backgroundColor: string, textColor: string}>`
  position: absolute;
  bottom: 50px;
  left: 0;
  width: 100%;
  padding-top: 0.2em;
  padding-bottom: 0.2em;

  font-size: 1.4em;
  font-weight: 900;
  letter-spacing: 0.15rem;

  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  color: ${props => props.textColor};
  background-color: ${props => props.backgroundColor};
  opacity: 0.8;
`

const StyDescription = styled.div<{textColor: string}>`
  padding-top: 1rem;
  color: ${props => props.textColor};
`

export type ProjectProps = {
  title: string,
  imageSlug: string,
  color: string,
  textColor: string,
  description: string,
  url: string,
}

const Project: FC<ProjectProps> = ({
  title, imageSlug, color, description, url, textColor = 'white',
}) => {
  return (
    <StyContainer backgroundColor={color}>
      <StyLink href={url} target="new">
        <StyImage src={imageSlug} />
        <StyTitle backgroundColor={color} textColor={textColor}>{title}</StyTitle>
      </StyLink>
      <StyDescription textColor={textColor}>{description}</StyDescription>
    </StyContainer>
  )
}

export default Project
