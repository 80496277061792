import { graphql, useStaticQuery } from 'gatsby'
import React, { FC } from 'react'
import styled from 'styled-components'

const StyAvatar = styled.img`
  border-radius: 50%;
  width: 8rem;
  border: 0.5rem solid rgb(67, 74, 90);
  box-shadow: 0 0.2rem 0.3rem 0 rgba(0, 0, 0, 0.8);

`

type GravatarQueryResult = {
  gravatar: { url: string }
}

const Avatar: FC<{}> = () => {
  const gravatarQueryResult: GravatarQueryResult = useStaticQuery(graphql`
    query GravatarQuery{
      gravatar(email: { eq: "michael.puls@mipnet.de" }) {
        url
      }
    }
  `)

  return (
    <StyAvatar src={gravatarQueryResult.gravatar.url} alt="Avatar" />
  )
}

export default Avatar
