import React, { FC } from 'react'
import styled from 'styled-components'
import Project, { ProjectProps } from './project'
import ProjectGroup from './project-group'

const StyGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
`

type Projects = {
  allex: ProjectProps
  tourCollect: ProjectProps
  issPass: ProjectProps
  brickFree: ProjectProps
  adventskalender: ProjectProps
}

const projects: Projects = {
  allex: {
    title: 'ALLEX',
    color: '#527BFE',
    textColor: '#fff',
    imageSlug: 'allex.png',
    description: `
      I'm currently working as a developer for a SaaS (Software as a Service) Project Management Software called ALLEX.
      It uses a unique methodology and covers single project-, portfolio- and resource management.
      It's based on a polyglot micro-service and micro-front-end architecture.`,
    url: 'https://allex.ai',
  },
  tourCollect: {
    title: 'TourCollect',
    color: '#4cb050',
    textColor: '#fff',
    imageSlug: 'tour-collect.jpg',
    description: `
      TourCollect is a GPL v3 JAVA based Android App with the main purpose to function as a replacement for a bike computer.
      Additionally, it works for tours by hiking, jogging or driving a car and provides statistics for each type of motion.`,
    url: 'https://gitlab.com/MPuls/tourcollect',
  },
  issPass: {
    title: 'ISS Pass',
    color: '#512da7',
    textColor: '#fff',
    imageSlug: 'iss-pass.jpg',
    description: `
      ISS Pass is a GPL v3 JAVA based Android App with the main purpose to show the next "visible" transitions of the International Space Station (ISS) over the sky at the user's current location.`,
    url: 'https://gitlab.com/MPuls/iss-pass',
  },
  brickFree: {
    title: 'Brick-free',
    color: '#ecb701',
    textColor: '#4f0327',
    imageSlug: 'brick-free.jpg',
    description: 'Things that I had in mind and constructed freely with ®Lego bricks.',
    url: 'https://brick-free.mipnet.de',
  },
  adventskalender: {
    title: 'Adventskalender',
    color: '#a30e00',
    textColor: '#fff',
    imageSlug: 'adventskalender.jpg',
    description: `
      Seit einigen Jahren schenke ich meiner Angebeteten für den Dezember einen Adventskalender.
      Damit andere, welche vielleicht auf der Suche nach Ideen sind, diese finden, möchte ich sie kurz festhalten.`,
    url: 'https://adventskalender.mipnet.de',
  },
}

const ProjectGroups: FC<{}> = () => {
  return (
    <StyGrid>
      <ProjectGroup title="Work">
        <Project {...projects.allex} />
      </ProjectGroup>
      <ProjectGroup title="Private : Code">
        <Project {...projects.issPass} />
        <Project {...projects.tourCollect} />
      </ProjectGroup>
      <ProjectGroup title="Privat : No Code">
        <Project {...projects.brickFree} />
        <Project {...projects.adventskalender} />
      </ProjectGroup>
    </StyGrid>
  )
}

export default ProjectGroups
