import React, { FC } from 'react'
import styled from 'styled-components'
import Avatar from './avatar'
import SocialMediaRow from './social-media-row'

const StyContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;
`

const StyName = styled.div`
  font-weight: 600;
  letter-spacing: 0.15rem;
  color: rgb(67, 74, 90);
  margin-bottom: 1rem;
`

const Michael: FC<{}> = () => {
  return (
    <StyContainer>
      <StyName>
        Michael Puls
      </StyName>
      <Avatar />
      <SocialMediaRow />
    </StyContainer>
  )
}

export default Michael
