import React, { FC } from 'react'
import styled from 'styled-components'
import { FaMastodon } from 'react-icons/fa'

const StySocialIconsContainer = styled.div`
  margin-top: 1rem;
  display: flex;
  font-size: 1rem;
  justify-content: flex-start;
`

const StySocialIcon = styled.div`
  display: flex;
  align-items: center;
  margin-right: 2rem;
`

const StySocialIconText = styled.div`
  margin-left: 0.5rem;
`

const SocialMediaRow: FC<{}> = () => (
  <StySocialIconsContainer>
    <a rel="me" href="https://muenchen.social/@puls" target="new" style={{ color: 'rgb(97, 146, 224)' }}>
      <StySocialIcon>
        <FaMastodon color="rgb(97, 146, 224)" size="20" />
        <StySocialIconText>Mastodon</StySocialIconText>
      </StySocialIcon>
    </a>
  </StySocialIconsContainer>
)

export default SocialMediaRow
