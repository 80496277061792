import React, { FC } from 'react'
import styled from 'styled-components'

const StyContainer = styled.div`
  background-color: rgb(67, 74, 90);
  box-shadow: 0.3rem 0.3rem 1rem -0.1rem rgba(0, 0, 0, 0.7);

  padding: 2rem;
  margin: 2rem;

  flex-grow: 1;

  @media only screen and (max-width: 600px) {
    padding: 1rem;
    margin: 1rem 0rem;
    /* margin-bottom: 1rem; */
  }
`

const StyProjectsContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: flex-start;
  flex-wrap: wrap;
`

const StyTitleContainer = styled.div`
  display: flex;
  justify-content: center;
`

const StyTitle = styled.div`
  margin-bottom: 2rem;
  opacity: 0.5;
  color: rgb(30,30,30);

  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;

  width: min(100%, 1000px);
`

const StyTitleLetter = styled.div`
  font-size: 1.8rem;
  text-transform: uppercase;

  @media only screen and (max-width: 600px) {
    font-size: 1.4em;
  }
`

export type ProjectGroupProps = {
  title?: string,
  children: React.ReactNode
}

const ProjectGroup: FC<ProjectGroupProps> = ({ title, children }) => {
  const titleLetters = title?.split('')
  return (
    <StyContainer>
      <StyTitleContainer>
        { titleLetters
          ? (
            <StyTitle>
              {
                titleLetters.map((letter, index) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <StyTitleLetter key={index}>
                    {letter}
                  </StyTitleLetter>
                ))
              }
            </StyTitle>
          )
          : null}
      </StyTitleContainer>
      <StyProjectsContainer>
        {children}
      </StyProjectsContainer>
    </StyContainer>
  )
}

export default ProjectGroup
