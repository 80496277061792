// eslint-disable-next-line no-use-before-define
import React, { FC } from 'react'
import PageWrapper from '../components/page-wrapper'
import Michael from '../components/michael'
import ProjectGroups from '../components/project-groups'

const Home: FC<{}> = () => (
  <PageWrapper>
    <Michael />
    <ProjectGroups />
  </PageWrapper>
)

export default Home
